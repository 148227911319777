import { isNil } from 'lodash-es';
import { startWith } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { DTO } from '@bp/shared/models/metadata';

import { UrlHelper } from '@bp/frontend/utilities/common';
import { RouteDataMetatags, RouteMetatags } from '@bp/frontend/features/metatags/models';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { RouterService } from '@bp/frontend/services/router';

@Injectable({
	providedIn: 'root',
})
export class MetatagsService {

	private readonly __environment = inject(EnvironmentService);

	private readonly __routerService = inject(RouterService);

	private readonly __activatedRoute = inject(ActivatedRoute);

	private readonly __ngMeta = inject(Meta);

	private __defaultMetatags!: RouteMetatags;

	private __inited = false;

	private __host!: string;

	init(host: string, defaultMetatags: RouteMetatags): void {
		if (this.__inited)
			return;

		this.__host = host;

		this.__defaultMetatags = defaultMetatags;

		this.__onNavigationEndSetMetatagsDeclaredInRoute();

		this.__inited = true;
	}

	setMetatags(metatags: DTO<RouteMetatags> | RouteMetatags): void {
		this.__updateMetatags(new RouteMetatags({
			...this.__defaultMetatags,
			...metatags,
			// disable any possible crawling of non production environment
			...(this.__environment.isProduction ? {} : { robots: [ 'none', 'noarchive' ]}),
		}));
	}

	private __updateMetatags(metatags: RouteMetatags): void {
		this.__updateHtmlMetatags(
			this.__addHostToUrlsIfNonePresent(metatags),
		);
	}

	private __addHostToUrlsIfNonePresent(metatags: RouteMetatags): RouteMetatags {
		return new RouteMetatags({
			...metatags,
			url: this.__prependDefaultHostNameToUrlIfNone(metatags.url),
			image: this.__prependDefaultHostNameToUrlIfNone(metatags.image),
		});
	}

	private __prependDefaultHostNameToUrlIfNone(url?: string | null): string | null {
		if (!url)
			return null;

		return url.startsWith('https://') ? url : `https://${ this.__host }${ url }`;
	}

	private __onNavigationEndSetMetatagsDeclaredInRoute(): void {
		this.__routerService.navigationEnd$
			.pipe(startWith({ url: '/' }))
			.subscribe(({ url }) => void this.__updateMetatags(
				this.__mergeLastPrimaryRouteMetatagsWithDefault(url),
			));
	}

	private __mergeLastPrimaryRouteMetatagsWithDefault(url: string): RouteMetatags {
		const lastPrimaryRouteData = <RouteDataMetatags> UrlHelper.getLastPrimaryRoute(this.__activatedRoute).snapshot.data;

		return new RouteMetatags({
			url,
			...this.__defaultMetatags,
			title: isNil(lastPrimaryRouteData.title) ? this.__defaultMetatags.title : lastPrimaryRouteData.title,
			...lastPrimaryRouteData.metatags,
		});
	}

	private __updateHtmlMetatags(metatags: RouteMetatags): void {
		this.__removeOldHtmlMetatags(metatags);

		this.__ngMeta.addTags(metatags.getMetaDefinitions());
	}

	private __removeOldHtmlMetatags(metatags: RouteMetatags): void {
		metatags
			.getMetatagSchemas()
			.flatMap(schema => this.__ngMeta.getTags(`property^='${ schema }:'`))
			.forEach(tag => void this.__ngMeta.removeTagElement(tag));

		metatags
			.getSeoMetatagsNames()
			.forEach(name => void this.__ngMeta.removeTag(`name="${ name }"`));
	}

}
