import { IdleMonitorService, isScullyGenerated, isScullyRunning } from '@scullyio/ng-lib';
import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScullyService {

	flashPreventionSwitchDone$ = new BehaviorSubject<boolean>(!isScullyGenerated());

	constructor(private readonly _idleMonitorService: IdleMonitorService) {
	}

	init(): void {
		this._onFlashPreventionEventMarkSwitchDone();
	}

	notifyAppReadyAfterRequiredAmountOfTime(): void {
		if (isScullyRunning())
			setTimeout(() => void this._idleMonitorService.fireManualMyAppReadyEvent(), 1000);
		else
			void this._idleMonitorService.fireManualMyAppReadyEvent();
	}

	private _onFlashPreventionEventMarkSwitchDone(): void {
		window.addEventListener('FlashPreventionSwitchDone', () => void this.flashPreventionSwitchDone$.next(true));
	}

}
